$(document).ready(function(){
  var lecture = 1;
  $("#home-video")[0].src += "&autoplay=1";

  $('#play-home-video').on('click', function() {
    if(lecture == 1){
      $('#home-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
      lecture = 0;
    }
    else{
      $('#home-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
      lecture = 1;
    }
  });

  resizeDiv();
});

window.onresize = function(event) {
  resizeDiv();
}

function resizeDiv() {
  window_width = $(window).width();

  if ($('.responsive-check').css('float') == 'left'){
    window_height = $(window).height() - $('#top').innerHeight();
    $('.home-slideshow').css({'height': window_height + 'px'});
  }
  else{
    $('.home-slideshow').css({'height': 'auto'});
  }

  console.log(window_height);
}

// function showGoogleMaps() {

//   var latLng = new google.maps.LatLng(45.759996, -17.526324);

//   var locations = [
//     ['Nantes', 47.233432, -1.557158, 5],
//     ['Paris', 48.854379, 2.351069, 4],
//     ['Lyon', 45.762220, 4.835890, 3],
//     ['Saint-Etienne', 45.434705, 4.381255, 2],
//     ['Roanne', 46.033935, 4.071180, 1]
//   ];

//   var mapOptions = {
//       zoom: 5, // initialize zoom level - the max value is 21
//       center: new google.maps.LatLng(45.759996, -17.526324),
//       streetViewControl: false, // hide the yellow Street View pegman
//       scaleControl: true, // allow users to zoom the Google Map
//       mapTypeId: google.maps.MapTypeId.ROADMAP,
//       center: latLng,
//       zoomControl: false,
//       mapTypeControl: false,
//       styles: [{"featureType":"administrative","elementType":"all","stylers":[{"saturation":"-100"}]},{"featureType":"administrative.province","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","elementType":"all","stylers":[{"saturation":-100},{"lightness":"50"},{"visibility":"simplified"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":"-100"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"lightness":"30"}]},{"featureType":"road.local","elementType":"all","stylers":[{"lightness":"40"}]},{"featureType":"transit","elementType":"all","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]},{"featureType":"water","elementType":"labels","stylers":[{"lightness":-25},{"saturation":-100}]}]
//   };

//   map = new google.maps.Map(document.getElementById('googlemaps'), mapOptions);

//   var iconBase = '/assets/themes/front/img/';

//   var infowindow = new google.maps.InfoWindow();

//   var marker, i;

//   for (i = 0; i < locations.length; i++) {
//     marker = new google.maps.Marker({
//       position: new google.maps.LatLng(locations[i][1], locations[i][2]),
//       icon: iconBase + 'marker.png',
//       map: map
//     });

//     google.maps.event.addListener(marker, 'click', (function(marker, i) {
//       return function() {
//         infowindow.setContent(locations[i][0]);
//         infowindow.open(map, marker);
//       }
//     })(marker, i));
//   }

// }

// google.maps.event.addDomListener(window, 'load', showGoogleMaps);
